import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import LeadParagraph from '../../../components/LeadParagraph';
import Image from '../../../components/Image';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "รายละเอียดของคอร์ส",
      "style": {
        "position": "relative"
      }
    }}>{`รายละเอียดของคอร์ส`}<a parentName="h1" {...{
        "href": "#%E0%B8%A3%E0%B8%B2%E0%B8%A2%E0%B8%A5%E0%B8%B0%E0%B9%80%E0%B8%AD%E0%B8%B5%E0%B8%A2%E0%B8%94%E0%B8%82%E0%B8%AD%E0%B8%87%E0%B8%84%E0%B8%AD%E0%B8%A3%E0%B9%8C%E0%B8%AA",
        "aria-label": "รายละเอียดของคอร์ส permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <LeadParagraph mdxType="LeadParagraph">
Electronics for Kid 101
    </LeadParagraph>
    <Image size="M" src="/images/services/electronis101.png" alt="Electronics for Kid 101" style={{
      "display": "block",
      "width": "100%",
      "minWidth": "600px",
      "maxWidth": "915px",
      "margin": "0 auto"
    }} mdxType="Image" />
    <h2 {...{
      "id": "เกี่ยวกับคอร์สเรียน",
      "style": {
        "position": "relative"
      }
    }}>{`เกี่ยวกับคอร์สเรียน`}<a parentName="h2" {...{
        "href": "#%E0%B9%80%E0%B8%81%E0%B8%B5%E0%B9%88%E0%B8%A2%E0%B8%A7%E0%B8%81%E0%B8%B1%E0%B8%9A%E0%B8%84%E0%B8%AD%E0%B8%A3%E0%B9%8C%E0%B8%AA%E0%B9%80%E0%B8%A3%E0%B8%B5%E0%B8%A2%E0%B8%99",
        "aria-label": "เกี่ยวกับคอร์สเรียน permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`หัวข้อที่เรียน:`}</p>
    <ul>
      <li parentName="ul">{`ไฟฟ้าคืออะไร?`}</li>
      <li parentName="ul">{`กระแสไฟฟ้าไหลได้อย่างไร?`}</li>
      <li parentName="ul">{`องค์ประกอบพื้นฐานของวงจรไฟฟ้า (แบตเตอรี่, หลอดไฟ, ตัวต้านทาน)`}</li>
      <li parentName="ul">{`เรียนรู้พื้นฐาน ขาสัญญาณจ่ายไฟ USB จากคอมพิวเตอร์ และ โทรศัพท์มือถือ`}</li>
      <li parentName="ul">{`การต่อวงจร LED ให้สว่าง `}</li>
      <li parentName="ul">{`รู้จักตัวต้านทาน (Resistor), ตัวเก็บประจุ (Capacitor), ไดโอด (Diode)`}</li>
      <li parentName="ul">{`เข้าใจหลักการทำงานของตัวต้านทานและการอ่านรหัสสี`}</li>
      <li parentName="ul">{`รู้จักอุปกรณ์ อิเล็กทรอนิกส์ อื่นๆ รวมถึงพัฒนาการ อุปกรณ์อิเล็กทรอนิกส์ และ เทคโนโลยี`}</li>
      <li parentName="ul">{`การต่อสายอุปกรณ์ เครื่องเสียงอย่างง่าย สำหรับ เล่นดนตรี และ บันทึกเสียง `}</li>
      <li parentName="ul">{`พื้นฐานเลข ฐาน 2 8 10 16 สำหรับ คอมพิวเตอร์ และ Micro-controller และ พื้นฐาน ภาษาที่ใช้กับ Micro-controller และ คอมพิวเตอร์`}</li>
    </ul>
    <h3 {...{
      "id": "เรียนก่อน-เป็นก่อน-ล้ำหน้ากว่าใครๆ",
      "style": {
        "position": "relative"
      }
    }}>{`เรียนก่อน เป็นก่อน ล้ำหน้ากว่าใครๆ`}<a parentName="h3" {...{
        "href": "#%E0%B9%80%E0%B8%A3%E0%B8%B5%E0%B8%A2%E0%B8%99%E0%B8%81%E0%B9%88%E0%B8%AD%E0%B8%99-%E0%B9%80%E0%B8%9B%E0%B9%87%E0%B8%99%E0%B8%81%E0%B9%88%E0%B8%AD%E0%B8%99-%E0%B8%A5%E0%B9%89%E0%B8%B3%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B8%81%E0%B8%A7%E0%B9%88%E0%B8%B2%E0%B9%83%E0%B8%84%E0%B8%A3%E0%B9%86",
        "aria-label": "เรียนก่อน เป็นก่อน ล้ำหน้ากว่าใครๆ permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h3 {...{
      "id": "คอร์สนี้เหมาะกับใคร",
      "style": {
        "position": "relative"
      }
    }}>{`คอร์สนี้เหมาะกับใคร`}<a parentName="h3" {...{
        "href": "#%E0%B8%84%E0%B8%AD%E0%B8%A3%E0%B9%8C%E0%B8%AA%E0%B8%99%E0%B8%B5%E0%B9%89%E0%B9%80%E0%B8%AB%E0%B8%A1%E0%B8%B2%E0%B8%B0%E0%B8%81%E0%B8%B1%E0%B8%9A%E0%B9%83%E0%B8%84%E0%B8%A3",
        "aria-label": "คอร์สนี้เหมาะกับใคร permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`บุคคลทั่วไปที่ต้องการเรียนเสริมความรู้ด้านการอิเลคทรอนิคส์พื้นฐานเพื่อนำไปใช้งานและต่อยอด`}</li>
      <li parentName="ul">{`น้องๆนักเรียนทั่วไป ห้อง SmartCom,SMTE,Gifted เรียนเพื่อนำความรู้ไปต่อยอดในชั้นเรียน`}</li>
    </ul>
    <h2 {...{
      "id": "เนื้อหาเกี่ยวกับคอร์สนี้",
      "style": {
        "position": "relative"
      }
    }}>{`เนื้อหาเกี่ยวกับคอร์สนี้`}<a parentName="h2" {...{
        "href": "#%E0%B9%80%E0%B8%99%E0%B8%B7%E0%B9%89%E0%B8%AD%E0%B8%AB%E0%B8%B2%E0%B9%80%E0%B8%81%E0%B8%B5%E0%B9%88%E0%B8%A2%E0%B8%A7%E0%B8%81%E0%B8%B1%E0%B8%9A%E0%B8%84%E0%B8%AD%E0%B8%A3%E0%B9%8C%E0%B8%AA%E0%B8%99%E0%B8%B5%E0%B9%89",
        "aria-label": "เนื้อหาเกี่ยวกับคอร์สนี้ permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h2 {...{
      "id": "กิจกรรม",
      "style": {
        "position": "relative"
      }
    }}>{`กิจกรรม`}<a parentName="h2" {...{
        "href": "#%E0%B8%81%E0%B8%B4%E0%B8%88%E0%B8%81%E0%B8%A3%E0%B8%A3%E0%B8%A1",
        "aria-label": "กิจกรรม permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <ul>
      <li parentName="ul">{`เรียนรู้พื้นฐาน การออกแบบวงจร การอ่านแผนผังวงจร (Schematic Diagram) อย่างง่าย ด้วย `}</li>
      <li parentName="ul">{`การใช้โปรแกรม คอมพิวเตอร์ LibreOffice Calsheet (คล้ายกับ Microsoft Excel) เพื่อคำนวณ วงจรไฟฟ้า ขั้นพื้นฐาน และ พื้นฐานการ Filter อักษร ส่งข้อมูล ระหว่างคอมพิวเตอร์ และ Micro-controller`}</li>
      <li parentName="ul">{`พื้นฐาน การเขียนโปรแกรม คอมพิวเตอร์ ด้วย Lazarus Free Pascal ขั้นพื้นฐาน สำหรับ การคำนวณทางด้าน วิทยาศาสตร์ อิเล็กทรอนิกส์ คำนวณเลขฐาน คอมพิวเตอร์ และ วิศวกรรม`}</li>
      <li parentName="ul">{`การสร้างโปรแกรม เกมส์ง่ายๆ ระบบไฟจราจร`}</li>
    </ul>
    <h2 {...{
      "id": "เครื่องมือซอฟต์แวร์ที่ใช้",
      "style": {
        "position": "relative"
      }
    }}>{`เครื่องมือ/ซอฟต์แวร์ที่ใช้`}<a parentName="h2" {...{
        "href": "#%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%A1%E0%B8%B7%E0%B8%AD%E0%B8%8B%E0%B8%AD%E0%B8%9F%E0%B8%95%E0%B9%8C%E0%B9%81%E0%B8%A7%E0%B8%A3%E0%B9%8C%E0%B8%97%E0%B8%B5%E0%B9%88%E0%B9%83%E0%B8%8A%E0%B9%89",
        "aria-label": "เครื่องมือซอฟต์แวร์ที่ใช้ permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <ul>
      <li parentName="ul">{`Ltspice (สำหรับการจำลองวงจร ขั้นพื้นฐาน)`}</li>
      <li parentName="ul">{`LibreOffice Calsheet `}</li>
      <li parentName="ul">{`Lazarus Free Pascal`}</li>
      <li parentName="ul">{`ชุดหลอดไฟ LED (พร้อม Power Bank ฟรี)`}</li>
    </ul>
    <h2 {...{
      "id": "เมื่อเรียนจบคอร์สนี้-เด็ก-ๆ-จะสามารถ",
      "style": {
        "position": "relative"
      }
    }}>{`เมื่อเรียนจบคอร์สนี้ เด็ก ๆ จะสามารถ`}<a parentName="h2" {...{
        "href": "#%E0%B9%80%E0%B8%A1%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B9%80%E0%B8%A3%E0%B8%B5%E0%B8%A2%E0%B8%99%E0%B8%88%E0%B8%9A%E0%B8%84%E0%B8%AD%E0%B8%A3%E0%B9%8C%E0%B8%AA%E0%B8%99%E0%B8%B5%E0%B9%89-%E0%B9%80%E0%B8%94%E0%B9%87%E0%B8%81-%E0%B9%86-%E0%B8%88%E0%B8%B0%E0%B8%AA%E0%B8%B2%E0%B8%A1%E0%B8%B2%E0%B8%A3%E0%B8%96",
        "aria-label": "เมื่อเรียนจบคอร์สนี้ เด็ก ๆ จะสามารถ permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <ul>
      <li parentName="ul">{`เข้าใจ วงจรไฟฟ้าขั้นพื้นฐาน`}</li>
      <li parentName="ul">{`อ่านและออกแบบแผนผังวงจรง่าย ๆ`}</li>
      <li parentName="ul">{`สามารถต่อสายอุปกรณ์ เครื่องเสียงอย่างง่าย สำหรับ เล่นดนตรี และ บันทึกเสียง ได้ `}</li>
      <li parentName="ul">{`มีพื้นฐานการใช้โปรแกรม Ltspice ขั้นพื้นฐาน เพื่อสร้างวงจรอิเล็กทรอนิกส์ Simulation รวมถึงเข้าใจถึงการออกแบบลายวงจร (ลายทองแดง หรือ Print Circuit Board) ขั้นพื้นฐาน จนถึงปัจจุบัน`}</li>
      <li parentName="ul">{`การใช้โปรแกรม คอมพิวเตอร์ LibreOffice Calsheet (คล้ายกับ Microsoft Excel) เพื่อคำนวณ วงจรไฟฟ้า ขั้นพื้นฐาน และ พื้นฐานการ Filter อักษร ส่งข้อมูล ระหว่างคอมพิวเตอร์ และ Micro-controller รวมถึง มีพื้นฐานการใช้โปรแกรม LibreOffice Calsheet (คล้ายกับ Microsoft Excel) เพื่อการคำนวณ การ Filter อักษร ไปใช้งานในชีวิตประจำวัน หรือ ในห้องเรียนตามหลักสูตร คอมพิวเตอร์ ของโรงเรียนได้อีกด้วย`}</li>
      <li parentName="ul">{`พื้นฐานเลข ฐาน 2 8 10 16 สำหรับ คอมพิวเตอร์ และ Micro-controller และ พื้นฐาน ภาษาที่ใช้กับ Micro-controller และ คอมพิวเตอร์ นอกจากนี้ การคำนวณ แปลง เลข ฐาน 2 8 10 16 มีอยู่ในหลักสูตร คณิตศาสตร์ ในระดับมัธยมศึกษา ตอนต้น, วิศวกรรม อิกเล็กทรอนิกส์ ดิจิตอล และ คอมพิวเตอร์ อีกด้วย`}</li>
      <li parentName="ul">{`รู้จักอุปกรณ์ อิเล็กทรอนิกส์ รวมถึงพัฒนาการ อุปกรณ์อิเล็กทรอนิกส์ และ เทคโนโลยี`}</li>
      <li parentName="ul">{`มีพื้นฐาน การเขียนโปรแกรม คอมพิวเตอร์ ด้วย Lazarus Free Pascal ขั้นพื้นฐาน สำหรับ การคำนวณทางด้าน วิทยาศาสตร์ อิเล็กทรอนิกส์ คำนวณเลขฐาน คอมพิวเตอร์ และ วิศวกรรม และ ยังใช้เป็นพื้นฐานการเขียน โปรแกรมคอมพิวเตอร์ ด้วยภาษาอื่น ได้อีกด้วย เช่น ภาษา C, Pascal, Java, Phython, Assembly ของ Micro-controller
ข้อมูลเพิ่มเติมที่สำคัญ:
โปรแกรม Ltspice ได้ถูกใช้ออกแบบลายวงจร มาอย่างยาวนาน รวมถึง ได้ใช้ ผลิตวงจรในอุตสาหกรรม อิเล็กทรอนิกส์ ใช้เรียนในสถาบันการศึกษา ทางด้าน อิเล็กทรอนิกส์ วิศกรรม มาอย่างยาวนานด้วยเช่นกัน ทั้งในประเทศไทย และ ต่างประเทศ เช่น Massachusetts Institute of Technology เป็นต้น`}</li>
    </ul>
    <h3 {...{
      "id": "ขั้นตอนการสอนของเรา",
      "style": {
        "position": "relative"
      }
    }}>{`ขั้นตอนการสอนของเรา`}<a parentName="h3" {...{
        "href": "#%E0%B8%82%E0%B8%B1%E0%B9%89%E0%B8%99%E0%B8%95%E0%B8%AD%E0%B8%99%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%AA%E0%B8%AD%E0%B8%99%E0%B8%82%E0%B8%AD%E0%B8%87%E0%B9%80%E0%B8%A3%E0%B8%B2",
        "aria-label": "ขั้นตอนการสอนของเรา permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`ค่อยทำเรียนทีละขึ้นตอนอย่างช้าๆไม่เร่งรีบ`}</li>
      <li parentName="ul">{`อธิบายคำสั่งอย่างละเอียดก่อนลงมือทำ`}</li>
      <li parentName="ul">{`ทำตัวอย่างให้ดูก่อนทุกครั้ง`}</li>
      <li parentName="ul">{`ลงมือปฏิบัติรายบุคคลจนสามารถใช้งานได้เข้าใจจริง`}</li>
    </ul>
    <h3 {...{
      "id": "อาจารย์ผู้สอน",
      "style": {
        "position": "relative"
      }
    }}>{`อาจารย์ผู้สอน`}<a parentName="h3" {...{
        "href": "#%E0%B8%AD%E0%B8%B2%E0%B8%88%E0%B8%B2%E0%B8%A3%E0%B8%A2%E0%B9%8C%E0%B8%9C%E0%B8%B9%E0%B9%89%E0%B8%AA%E0%B8%AD%E0%B8%99",
        "aria-label": "อาจารย์ผู้สอน permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`อ.สันติ วิศกรด้านออกแบบวางระบบเครือข่ายระดับประเทศ`}</li>
      <li parentName="ul">{`อดีตผู้เขียนคอลัมน์อีเลคทรอนิคส์ ในนิตยสาร อีเลคทรอนิคส์ ของสำนักพิมพ์ซีเอ็ด`}</li>
      <li parentName="ul">{`ผู้เขียนหนังสือเดลไฟสำหรับงานวิศวกรรม`}</li>
    </ul>
    <h3 {...{
      "id": "จำนวน-20-ชั่วโมง",
      "style": {
        "position": "relative"
      }
    }}>{`จำนวน 20 ชั่วโมง`}<a parentName="h3" {...{
        "href": "#%E0%B8%88%E0%B8%B3%E0%B8%99%E0%B8%A7%E0%B8%99-20-%E0%B8%8A%E0%B8%B1%E0%B9%88%E0%B8%A7%E0%B9%82%E0%B8%A1%E0%B8%87",
        "aria-label": "จำนวน 20 ชั่วโมง permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`ค่าใช้จ่าย 4500 บาท`}</li>
      <li parentName="ul">{`ระยะเวลาเรียน 20 ชั่วโมง ตารางเรียนเลือกจากเวลาที่เปิดครับ`}</li>
      <li parentName="ul">{`เรียนผ่านโปรแกรมซูม `}</li>
      <li parentName="ul">{`เรียนซ้ำได้จนกว่าจะเข้าใจ`}</li>
    </ul>
    <h3 {...{
      "id": "การสมัครเรียน",
      "style": {
        "position": "relative"
      }
    }}>{`การสมัครเรียน`}<a parentName="h3" {...{
        "href": "#%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%AA%E0%B8%A1%E0%B8%B1%E0%B8%84%E0%B8%A3%E0%B9%80%E0%B8%A3%E0%B8%B5%E0%B8%A2%E0%B8%99",
        "aria-label": "การสมัครเรียน permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`โทร: 086-943-1115`}</li>
      <li parentName="ul">{`ติดต่อผ่าน Email: `}<a parentName="li" {...{
          "href": "mailto:anndream.com@gmail.com"
        }}>{`anndream.com@gmail.com`}</a>{` `}</li>
      <li parentName="ul">{`line : frankpkth`}</li>
      <li parentName="ul">{`จะได้รับการติดต่อกลับภายใน 24 ชั่วโมง เพื่อนัดการจ่ายเงินก่อนเริ่มนัดหมายการสอน`}</li>
    </ul>
    <h3 {...{
      "id": "โปรดระวังมิจฉาชีพทุกกรณีทางเราจะไม่มีการส่ง-link-ใดๆไปให้ลูกค้ากดเด็ดขาด",
      "style": {
        "position": "relative"
      }
    }}><strong parentName="h3"><em parentName="strong">{`โปรดระวังมิจฉาชีพทุกกรณีทางเราจะไม่มีการส่ง link ใดๆไปให้ลูกค้ากดเด็ดขาด`}</em></strong><a parentName="h3" {...{
        "href": "#%E0%B9%82%E0%B8%9B%E0%B8%A3%E0%B8%94%E0%B8%A3%E0%B8%B0%E0%B8%A7%E0%B8%B1%E0%B8%87%E0%B8%A1%E0%B8%B4%E0%B8%88%E0%B8%89%E0%B8%B2%E0%B8%8A%E0%B8%B5%E0%B8%9E%E0%B8%97%E0%B8%B8%E0%B8%81%E0%B8%81%E0%B8%A3%E0%B8%93%E0%B8%B5%E0%B8%97%E0%B8%B2%E0%B8%87%E0%B9%80%E0%B8%A3%E0%B8%B2%E0%B8%88%E0%B8%B0%E0%B9%84%E0%B8%A1%E0%B9%88%E0%B8%A1%E0%B8%B5%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%AA%E0%B9%88%E0%B8%87-link-%E0%B9%83%E0%B8%94%E0%B9%86%E0%B9%84%E0%B8%9B%E0%B9%83%E0%B8%AB%E0%B9%89%E0%B8%A5%E0%B8%B9%E0%B8%81%E0%B8%84%E0%B9%89%E0%B8%B2%E0%B8%81%E0%B8%94%E0%B9%80%E0%B8%94%E0%B9%87%E0%B8%94%E0%B8%82%E0%B8%B2%E0%B8%94",
        "aria-label": "โปรดระวังมิจฉาชีพทุกกรณีทางเราจะไม่มีการส่ง link ใดๆไปให้ลูกค้ากดเด็ดขาด permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h3 {...{
      "id": "ไม่มั่นใจให้ติดต่อผ่านเบอร์โทรศัพท์ก่อน",
      "style": {
        "position": "relative"
      }
    }}><strong parentName="h3"><em parentName="strong">{`ไม่มั่นใจให้ติดต่อผ่านเบอร์โทรศัพท์ก่อน`}</em></strong><a parentName="h3" {...{
        "href": "#%E0%B9%84%E0%B8%A1%E0%B9%88%E0%B8%A1%E0%B8%B1%E0%B9%88%E0%B8%99%E0%B9%83%E0%B8%88%E0%B9%83%E0%B8%AB%E0%B9%89%E0%B8%95%E0%B8%B4%E0%B8%94%E0%B8%95%E0%B9%88%E0%B8%AD%E0%B8%9C%E0%B9%88%E0%B8%B2%E0%B8%99%E0%B9%80%E0%B8%9A%E0%B8%AD%E0%B8%A3%E0%B9%8C%E0%B9%82%E0%B8%97%E0%B8%A3%E0%B8%A8%E0%B8%B1%E0%B8%9E%E0%B8%97%E0%B9%8C%E0%B8%81%E0%B9%88%E0%B8%AD%E0%B8%99",
        "aria-label": "ไม่มั่นใจให้ติดต่อผ่านเบอร์โทรศัพท์ก่อน permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h3 {...{
      "id": "เตรียมความพร้อมก่อนเรียน",
      "style": {
        "position": "relative"
      }
    }}>{`เตรียมความพร้อมก่อนเรียน`}<a parentName="h3" {...{
        "href": "#%E0%B9%80%E0%B8%95%E0%B8%A3%E0%B8%B5%E0%B8%A2%E0%B8%A1%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%9E%E0%B8%A3%E0%B9%89%E0%B8%AD%E0%B8%A1%E0%B8%81%E0%B9%88%E0%B8%AD%E0%B8%99%E0%B9%80%E0%B8%A3%E0%B8%B5%E0%B8%A2%E0%B8%99",
        "aria-label": "เตรียมความพร้อมก่อนเรียน permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`คอมพิวเตอร์หรือโน๊ตบุค`}</li>
      <li parentName="ul">{`เชื่อมต่อไวไฟ`}</li>
      <li parentName="ul">{`ห้องส่วนตัวหรือบริเวณที่ไม่เกิดเสียงดังรบกวนระหว่างเรียน`}</li>
      <li parentName="ul">{`โปรแกรมซูม`}</li>
      <li parentName="ul">{`สมุดดินสอ ปากกา`}</li>
      <li parentName="ul">{`ความตั้งใจ `}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      